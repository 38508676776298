import Swiper from 'swiper'
import 'swiper/css'
import 'swiper/css/effect-fade'

import { defineComponent } from '~/scripts/utils/alpine'

const SLIDES_PER_VIEW = 3

export default defineComponent(() => ({
  swiperInstance: undefined as Swiper | undefined,
  activeIndex: 0,
  init() {
    this.swiperInstance = new Swiper(this.$refs.swiper, {
      slidesPerView: SLIDES_PER_VIEW,
      spaceBetween: 20,
      loop: true,
      grabCursor: true,
      on: {
        slideChange: (swiper) => {
          this.activeIndex = swiper.activeIndex
        },
      },
    }) as Swiper

    // disable swiper if not enough slides
    if (this.swiperInstance.slides.length <= SLIDES_PER_VIEW) {
      this.swiperInstance.disable()
    }
  },
}))
