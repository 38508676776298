import Swiper from 'swiper'
import { A11y, Controller, EffectFade, Navigation } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/a11y'
import 'swiper/css/controller'
import 'swiper/css/effect-fade'

import { useElementSize } from '~/scripts/composables/useElementSize'
import { defineComponent } from '~/scripts/utils/alpine'
import screens from '~/config/screens.json'

export default defineComponent(() => ({
  iconCardHeight: 0,
  isLocked: false,
  init() {
    const { descriptions, next, prev, services } = this.$refs

    const updateAriaSelected = ({ activeIndex, slides }: Swiper) => {
      for (const [index, slide] of slides.entries()) {
        slide.setAttribute(
          'aria-selected',
          index === activeIndex ? 'true' : 'false',
        )
      }
    }

    const countSlides = services.querySelectorAll('.swiper-slide').length
    const initialSlide = Math.floor(countSlides / 2)

    const servicesSwiper = new Swiper(services, {
      slidesPerView: 3,
      initialSlide,
      spaceBetween: 0,
      centeredSlides: true,
      centerInsufficientSlides: true,
      slideToClickedSlide: true,
      modules: [A11y, Controller, Navigation],
      navigation: { nextEl: next, prevEl: prev },
      breakpoints: {
        [Number.parseInt(screens.lg)]: {
          spaceBetween: 40,
          centeredSlides: false,
        },
        [Number.parseInt(screens.xl)]: {
          spaceBetween: 64,
          centeredSlides: false,
        },
      },
      on: {
        init: (swiper) => {
          updateAriaSelected(swiper)
        },
        activeIndexChange: (swiper) => {
          updateAriaSelected(swiper)
        },
        lock: () => {
          this.isLocked = true
        },
        unlock: () => {
          this.isLocked = false
        },
      },
    })

    const descriptionsSwiper = new Swiper(descriptions, {
      effect: 'fade',
      autoHeight: true,
      initialSlide,
      controller: { control: servicesSwiper },
      modules: [A11y, Controller, EffectFade],
    })

    servicesSwiper.controller.control = descriptionsSwiper

    useElementSize(
      services.querySelector('.icon-card')!,
      (size) => (this.iconCardHeight = size.height),
    )
  },
}))
