import { defineComponent } from '~/scripts/utils/alpine'
import { useStore } from '~/scripts/composables/useStore'

export default defineComponent(() => ({
  activeSubmenu: undefined as string | undefined,
  init() {
    this.$watch('visible', (value: boolean) => {
      if (!value) {
        this.closeSubmenu()
      }
    })
  },
  get visible() {
    return useStore(this).ui.menuVisible
  },
  set visible(value: boolean) {
    useStore(this).ui.setMenuVisible(value)
  },
  toggleVisible() {
    console.log('toggle visible')
    this.visible = !this.visible
  },
  closeSubmenu() {
    this.activeSubmenu = undefined
  },
}))
