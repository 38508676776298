import { defineComponent } from '~/scripts/utils/alpine'
import { useStore } from '~/scripts/composables/useStore'
import { useElementSize } from '~/scripts/composables/useElementSize'

export default defineComponent(() => {
  return {
    init() {
      const store = useStore(this)
      useElementSize(this.$el, (size) => {
        store.ui.setHeaderHeight(size.height)
      })
    },
  }
})
