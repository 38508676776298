import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import persist from '@alpinejs/persist'
import autoAnimate from '@marcreichel/alpine-auto-animate'

import { gsap, Expo } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { useLenis } from '~/scripts/composables/useLenis'

import { omit } from 'radash'
import { useDataScroll } from 'data-scroll'

import registerStoreModules from '~/scripts/store'
import registerDirectives from '~/scripts/directives'
import registerComponents from '~/scripts/components'
import registerMagics from '~/scripts/magics'

import { scrollToAnchor } from '~/scripts/utils/scroll'
import screens from '~/config/screens.json'

export default () => {
  gsap.registerPlugin(ScrollTrigger)

  useLenis({
    duration: 1.2,
    prevent: ['#wpadminbar', '#query-monitor-main', '.cky-modal'],
    easing: Expo.easeOut,
    useTicker(lenis) {
      lenis.on('scroll', ScrollTrigger.update)

      gsap.ticker.add((time) => {
        lenis.raf(time * 1000)
      })

      gsap.ticker.lagSmoothing(0)
    },
  })

  window.Alpine = Alpine

  // Register plugins
  Alpine.plugin(collapse)
  Alpine.plugin(persist)
  Alpine.plugin(autoAnimate)

  registerMagics()
  registerStoreModules()
  registerDirectives()
  registerComponents()

  document.addEventListener('alpine:initialized', () => {
    // Make Alpine's store available globally
    const result = document.querySelector('[x-data]')

    if (result) {
      // @ts-expect-error Alpine's store will be available at this path so this is type-safe
      window.$store = result._x_dataStack[0].$store
    } else {
      throw new Error(
        'No x-data found, the page should have at least one element with the x-data attribute.',
      )
    }

    useDataScroll({ screens: omit(screens, ['touch', 'not-touch']) })
  })

  Alpine.start()

  // DataLayer events
  if (window.dataLayer) {
    for (const element of document.querySelectorAll<HTMLElement>(
      '[data-event]',
    )) {
      element.addEventListener('click', () => {
        const { event } = element.dataset
        if (event) {
          window.dataLayer?.push({ event })
        }
      })
    }
  }

  // Smooth scroll to anchors
  for (const anchor of document.querySelectorAll<HTMLAnchorElement>(
    'a[href*="#"]:not([href$="#"])',
  )) {
    anchor.addEventListener('click', (event) => {
      scrollToAnchor(anchor, event, { blacklist: [] })
    })
  }
}
