import Alpine from 'alpinejs'
import gsap from 'gsap'

import { defineComponent } from '~/scripts/utils/alpine'
import { useStore } from '~/scripts/composables/useStore'
import screens from '~/config/screens.json'

const MESSAGES = {
  already_participated: 'Vous avez déjà participé à ce concours.',
  contest_over: 'Le concours est terminé.',
  share_title: 'Lapo - Jeu concours',
  share_message: "J'ai participé au jeu concours Lapo !",
  error_disposable_email:
    'Les adresses e-mail jetables ne sont pas autorisées.',
  error_existing_user: 'Cet email a déjà été utilisé.',
}

export default defineComponent(() => ({
  totalSteps: 2,
  currentStep: 0,
  panelOpened: false,
  isAvailable: true,
  isLoading: false,
  hasAlreadyParticipated: Alpine.$persist(false),
  // Prize drawn
  prize: undefined as { id: number; name: string } | undefined,
  // Form
  formErrorMessage: undefined as string | undefined,
  formData: {
    email: undefined as string | undefined,
    firstname: undefined as string | undefined,
    lastname: undefined as string | undefined,
  },
  async init() {
    const store = useStore(this)
    this.$watch('panelOpened', async (value) => {
      if (value) {
        store.ui.lockScrollBy('contest')

        if (this.hasAlreadyParticipated) {
          this.formErrorMessage = MESSAGES.already_participated
        } else {
          const isAvailable = await this.checkAvailability()
          if (isAvailable) {
            // Focus first input
            const firstField = this.$root.querySelector<HTMLInputElement>(
              'form input[type="text"]:first-child',
            )
            if (firstField) {
              firstField.focus()
            }
          } else {
            // Contest is over
            this.formErrorMessage = MESSAGES.contest_over
            this.isAvailable = false
          }
        }
      } else {
        store.ui.unlockScrollBy('contest')
      }
    })
  },
  go(step: number) {
    this.currentStep = step
  },
  async open() {
    // Reset to first step
    this.currentStep = 0
    await this.$nextTick()

    this.panelOpened = true
  },
  close() {
    this.panelOpened = false
  },
  runWheel() {
    const {
      wheelBody,
      wheel,
      prizeTitle,
      prizeSeparator,
      prizeDescription,
      prizeFooter,
    } = this.$refs

    const timeline = gsap.timeline({ delay: 0.5 })

    const isMdScreen = window.matchMedia(`(min-width: ${screens.md})`).matches

    // Wheel initially centered
    if (isMdScreen) {
      gsap.set(wheelBody, { x: '50%' })
    }

    timeline.to(wheel, {
      duration: 4,
      rotate: 2029,
      ease: 'power3.out',
    })

    if (isMdScreen) {
      timeline.to(wheelBody, { x: 0, duration: 0.5, ease: 'power3.inOut' })
    }

    timeline
      .fromTo(
        prizeTitle,
        {
          autoAlpha: 0,
          scale: 0,
        },
        {
          scale: 1,
          autoAlpha: 1,
          duration: 1,
          ease: 'elastic.out(1.5,0.75)',
        },
        isMdScreen ? '-=0.3' : undefined,
      )
      .fromTo(
        [prizeSeparator, ...prizeDescription.children, prizeFooter],
        {
          autoAlpha: 0,
          y: 10,
        },
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          stagger: 0.1,
          ease: 'power3.out',
        },
        '-=0.7',
      )
  },
  async draw() {
    this.isLoading = true

    if (!(await this.checkAvailability())) {
      this.formErrorMessage = MESSAGES.contest_over
      this.isAvailable = false
      this.isLoading = false
      return
    }

    const response = await fetch('/wp-json/contest/prize-draw', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(this.formData),
    })

    const data = await response.json()

    if (!response.ok) {
      // Message can of different types
      if (typeof data.message === 'string') {
        this.formErrorMessage = data.message
      } else if (Array.isArray(data.message)) {
        this.formErrorMessage = data.message.join('\n')
      } else if (typeof data.message === 'object') {
        this.formErrorMessage = Object.values(data.message).join('\n')
      }

      this.isLoading = false
      return
    }

    this.prize = {
      id: data.id,
      name: data.name,
    }

    this.isLoading = false
    this.hasAlreadyParticipated = true

    this.go(1)
    this.runWheel()
  },
  async checkAvailability(): Promise<boolean> {
    const response = await fetch('/wp-json/contest/available')
    const data = await response.json()

    if (!response.ok) {
      this.formErrorMessage = data.message
      return false
    }

    return true
  },
  get canShare() {
    return navigator.canShare && navigator.canShare()
  },
  share() {
    if (!navigator.share) {
      return
    }

    navigator.share({
      title: MESSAGES.share_title,
      text: MESSAGES.share_message,
      url: window.location.href,
    })
  },
}))
